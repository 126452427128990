































































import { Component, Vue } from "vue-property-decorator";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import OdooModule from "@/store/modules/Admin/Odoo-module";
import DataTable from "@/components/DatatableGenerico/DataTable.vue";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import EmailsModule from "@/store/modules/Emails/Emails-module";

@Component({
  components: { DataTable },
})
export default class AdminOperationsOdoo extends Vue {
  public shw: boolean = false;
  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    /*opts.push(
      new buttonOptions({
        id: 1,
        title: "Syncronizar contactos (SendingBlue)",
        icono: "fad fa-sync-alt",
        color: "blue darken-2",
        subtitle:
          "sincroniza los contactos de Odoo a sending blue. elimina de sending blue los que en odoo este marcados como que no quieren recibir correos electronicos y inserta en sending blue los contactos que no esten creados.",
        EventMethod: this.syncContacts
      })
    );

    opts.push(
      new buttonOptions({
        id: 2,
        title: "Syncronizar contactos caducidades",
        icono: "fad fa-sync-alt",
        color: "red",
        subtitle: "Test",
        EventMethod: this.syncCaducidades
      })
    );*/

    opts.push(
      new buttonOptions({
        id: 3,
        title: "Duplicados",
        icono: "fad fa-sync-alt",
        color: "primary lighten-1",
        subtitle: "Contactos duplicados de Odoo",
        EventMethod: this.getDuplicados,
      })
    );
    opts.push(
      new buttonOptions({
        id: 4,
        title: "Enviar nueva versión",
        icono: "fad fa-code-merge",
        color: "purple lighten-2",
        subtitle: "Enviar a los contactos que tenemos una nueva versión",
        EventMethod: this.SendNewVersion,
      })
    );
    return opts;
  }
  public syncContacts() {
    OdooModule.getSync_contact();
  }
  public syncCaducidades() {
    OdooModule.getSync_caducidades();
  }
  public async SendNewVersion() {
    await EmailsModule.SendNuevaVersion();
  }
  public getDuplicados() {
    OdooModule.getContactosDuplicados().then(() => (this.shw = true));
  }
  public get datos_duplicados() {
    return OdooModule.contactos;
  }

  get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "display_name",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      ).ChangeWidth(100)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "email",
        "Email",
        datatypes.string,
        true,
        datatypes.maxlength
      ).ChangeWidth(250)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "x_correos_newslatter",
        "newslatter",
        datatypes.boolean,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "phone",
        "Telefono",
        datatypes.number,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "x_caducidad_licencia",
        "Hasta",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
}
