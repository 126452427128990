import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { contactoOdoo } from '@/shared/dtos/admin/contactoOdoo';

@Module({
    namespaced: true,
    name: 'OdooModule',
    store,
    dynamic: true
})
class OdooModule extends VuexModule {
    public contactos: contactoOdoo[] = [];
    @Action
    public async getSync_contact() {
        return await ssmHttpService.get(API.SendingBlue + '/sync_contact');
    }

    @Action
    public async getSync_caducidades() {
        return await ssmHttpService.get(API.SendingBlue + '/test_contact');
    }

    @Action({ commit: 'onGetduplicados' })
    public async getContactosDuplicados() {
        return await ssmHttpService.get(API.SendingBlue + '/contactos_duplicados');
    }

    @Mutation
    public async onGetduplicados(res: contactoOdoo[]) {
        this.contactos = res ? res.map((x) => new contactoOdoo(x)) : [];
    }
}
export default getModule(OdooModule);